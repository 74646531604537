const Creds = {
    // Was for Spoonacular
    // key: '89de6c48c4b14a1fabdd04d76cb8a093',

    // For Spoonacular through rapid API
    key: '71ef5819e6msh478ab878673b705p10dde3jsn7b565b485399',
}

export {
    Creds
}